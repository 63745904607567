// LivestreamCardUserBadges.js
import { connect } from 'react-redux';

import ProfileInfoBadges from '../component/ProfileInfoBadges.jsx';

import getUserData from '../selector/getUserData.js';
import getRemoteConfigData from '../selector/getRemoteConfigData.js';
import {
  PUBLIC_BADGE_URL_PATH,
  PUBLIC_LEADERBOARD_URL_PATH,
} from '../RemoteConfigKeys.js';

const mapStateToProps = (state, { userId }) => {
  return {
    publicBadgeUrlPath: getRemoteConfigData(state, PUBLIC_BADGE_URL_PATH),
    publicLeaderboardUrlPath: getRemoteConfigData(
      state,
      PUBLIC_LEADERBOARD_URL_PATH
    ),
    badges: getUserData(state, userId, 'livestreamCardBadges'),
  };
};

export default connect(mapStateToProps)(ProfileInfoBadges);
