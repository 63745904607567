// Flip.jsx
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const isServer = typeof window === 'undefined';

/**
 * get component string
 * @param {ReactElement} component - react component.
 * @return {string} return inner string
 */
const getInnerString = component => {
  const isReactElement = React.isValidElement(component);
  if (isReactElement) {
    return getInnerString(component.props.children);
  }
  return component;
};

export class Flip extends React.PureComponent {
  state = {
    characterHeight: 0,
  };
  element = null;
  nextTick = null;
  setRef = element => {
    this.element = element;
  };
  renderCharacterRibbon = ({ activeCharacter, key }) => {
    const { ribbon, isDisabled } = this.props;
    const { characterHeight } = this.state;
    const characterIndex = ribbon.indexOf(activeCharacter);
    const needFlip = characterIndex >= 0;

    if (!needFlip) {
      return (
        <Separator key={key}>
          <Character>{activeCharacter}</Character>
        </Separator>
      );
    }

    return (
      <Ribbon
        key={key}
        characterIndex={characterIndex}
        characterHeight={characterHeight}
        isDisabled={isDisabled}
      >
        {ribbon.map((character, index) => {
          return (
            <Character
              key={`${key}-${index}`}
              characterHeight={characterHeight}
            >
              {character}
            </Character>
          );
        })}
      </Ribbon>
    );
  };
  getCharacterHeight = () => {
    if (this.element && !isServer) {
      const height = window
        .getComputedStyle(this.element)
        ['line-height'].replace('px', '');
      this.setState({ characterHeight: Number(height) });
    }
  };
  handleResize = () => {
    this.getCharacterHeight();
  };
  componentDidMount() {
    if (!isServer) {
      window.addEventListener('resize', this.handleResize);
      this.nextTick = setTimeout(() => {
        this.getCharacterHeight();
      });
    }
  }
  componentWillUnmount() {
    if (!isServer) {
      window.removeEventListener('resize', this.handleResize);
    }
    clearTimeout(this.nextTick);
  }
  render() {
    const { children } = this.props;
    const { characterHeight } = this.state;
    const isReactElement = React.isValidElement(children);
    const characters = isReactElement
      ? getInnerString(children).split('')
      : children.split('');

    const FlipComponent = (
      <StyledFlip ref={this.setRef} characterHeight={characterHeight}>
        {characters.map((character, index) => {
          return this.renderCharacterRibbon({
            activeCharacter: character,
            key: index,
          });
        })}
      </StyledFlip>
    );

    if (isReactElement) {
      return React.cloneElement(children, {
        children: FlipComponent,
      });
    }

    return FlipComponent;
  }
}

Flip.propTypes = {
  children: PropTypes.node.isRequired,
  ribbon: PropTypes.array,
  isDisabled: PropTypes.bool,
};

Flip.defaultProps = {
  ribbon: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
  isDisabled: false,
};

const StyledFlip = styled.div.attrs(({ characterHeight }) => ({
  style: {
    height: `${characterHeight}px`,
  },
}))`
  overflow-y: hidden;
`;

const Separator = styled.div`
  display: inline-block;
`;

const Ribbon = styled.div.attrs(
  ({ characterIndex = 0, characterHeight = 0, isDisabled }) => ({
    style: {
      transform: `translateY(-${characterIndex * characterHeight}px)`,
      transition: isDisabled ? 'initial' : 'transform 0.7s',
    },
  })
)`
  display: inline-block;
  position: relative;
  vertical-align: top;
  text-align: center;
`;
const Character = styled.div.attrs(({ characterHeight }) => ({
  style: {
    height: `${characterHeight}px`,
  },
}))``;

export default Flip;
