// Loading.jsx
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { loadingIconOpacity } from '../style/variables.js';

import LoadingWhiteResource from '../../img/img_loading_white_20.svg';
import LoadingSkyResource from '../../img/img_loading_sky_20.svg';
import LoadingYellowResource from '../../img/img_loading_yellow_20.svg';
import LoadingGrayResource from '../../img/img-loading-gray.svg';
import LoadingDarkResource from '../../img/img-loading-dark-20.svg';
import LoadingTealblueResource from '../../img/img-loading-tealblue-20.svg';

export const LoadingType = {
  WHITE: 'white',
  GRAY: 'gray',
  DARK: 'dark',
  TEALBLUE: 'tealblue',
  SKY: 'sky',
  YELLOW: 'yellow',
};

export const Loading = ({
  type = LoadingType.WHITE,
  size = 32,
  style = {},
}) => {
  return (
    <StyledLoading style={style}>
      <LoadingIcon
        src={
          LoadingType.GRAY === type
            ? LoadingGrayResource.src || LoadingGrayResource
            : LoadingType.DARK === type
              ? LoadingDarkResource.src || LoadingDarkResource
              : LoadingType.TEALBLUE === type
                ? LoadingTealblueResource.src || LoadingTealblueResource
                : LoadingType.SKY === type
                  ? LoadingSkyResource.src || LoadingSkyResource
                  : LoadingType.YELLOW === type
                    ? LoadingYellowResource.src || LoadingYellowResource
                    : LoadingWhiteResource.src || LoadingWhiteResource
        }
        size={size}
        alt="loading"
      />
    </StyledLoading>
  );
};

Loading.propTypes = {
  type: PropTypes.string,
  size: PropTypes.number,
  style: PropTypes.object,
};

const StyledLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  opacity: ${loadingIconOpacity};
`;

const LoadingIcon = styled.img.attrs(({ size }) => ({
  style: {
    width: `${size}px`,
  },
}))`
  height: auto;
`;

export default Loading;
